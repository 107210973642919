import React, { Component } from 'react';
import styled from 'styled-components';
import { Input, Button, message } from 'antd';
import { Container, Row, Col, Form } from 'react-bootstrap';
import axios from "axios";
import PageHeader from "../components/pageHeader";
import * as qs from "query-string";
import Layout from '../components/layout';
import ScrollAnimation from "react-animate-on-scroll";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import goArrow from "../assets/icon-arrow.svg";
import SEO from '../components/SEO';
import melFeatured from '../assets/MEL/mel-hero-featured@2x.png';
import MelOverview from '../assets/MEL/mel-overview.svg';
import MelCreate from '../assets/MEL/mel-create.svg';
import MelRevise from '../assets/MEL/mel-revise.svg';
import MelPublish from '../assets/MEL/mel-publish.svg';
import MelRegulator from '../assets/MEL/mel-regulator.png';
import MelTechLog from '../assets/MEL/mel-tech-log@2x.png';
import editorGif from '../assets/MEL/editor.gif';
import fleetGif from '../assets/MEL/fleet.gif';
import regulatorsGif from '../assets/MEL/regulators.gif';
import MelOversight from '../assets/MEL/mel-instant-oversight@2x.png';
import MelRegulators from '../assets/MEL/mel-regulators@2x.png';
import MelReview from '../assets/MEL/mel-track-review@2x.png';
import Tick from "../assets/icon-tick.svg";
import styles from './mel-manager.module.less';
import Started from '../components/Layout/started';

const { TextArea } = Input;

const Title24 = styled.div`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
`;

const Title30 = styled.div`
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 30px;
`;

const SubTitle20 = styled.div`
  font-size: 20px;
  letter-spacing: 0.03px;
  text-align: center;
  color: #242d41;
  max-width: 650px;
  margin-bottom: 50px;
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  margin-top: 90px;
`;

const GradientSectionReversed = styled.div`
  background-image: linear-gradient(to bottom, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  margin-top: 90px;
`;

const TabTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #3382e6;
  text-align: center;
  margin-bottom: 10px;
`;

const TabButton = styled.button`
  width: 100%;
  padding: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  position: relative;
  outline: none;
  &:focus {
    outline: none;
  }
`;

const TabLine = styled.div`
  width: 100%;
  position: absolute;
  height: 2px;
  ${props => props.active ? 'background-color: #3382e6;' : 'background-color: transparent;'}
  ${TabButton}:hover & {
    background-color: #3382e6;
    transition: background-color 0.5s linear;
  }
`;

const TabNumber = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${props => props.active ? 'background-color: #3382e6;' : 'background-color: #c2daf8;'}
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.03px;
  color: #ffffff;
  ${TabButton}:hover & {
    background-color: #3382e6;
    transition: background-color 1000ms linear;
  }
`;

const TabInnerTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.56;
  letter-spacing: 0.03px;
  color: #242d41;
  margin-bottom: 25px;
`;

const TabText = styled.div`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
`;

const TickWrapper = styled.div`
  margin-top: 40px;
`;

const tick = (
  <div className={styles.tick}>
    <img src={Tick} alt="tick" />
  </div>
);

const createTab = {
  title: 'Create an MEL from scratch or have us bring in your existing MELs',
  text: 'Issuing a new MEL is as simple as selecting the MMEL and using our powerful platform to alter the procedures and items to fit your operation.',
  text2: 'If you have an existing aircraft of the same type, or registered in the same location, even better. Changes you\'ve previously made to an MMEL never need to be performed twice. With a few easy clicks you can customise the MEL for a specific registry or bring in changes previously applied to a different aircraft.',
  text3: 'Tailored individual aircraft MELs become the new normal, by simply selecting the appropriate configurations for each aircraft, while managing revisions at a fleet-level. This reduces overhead and ensures that crew are never having to cross-reference serial numbers.',
  img: MelCreate,
  ticks: [
    'Complete MEL creation without any formatting',
    'Full library of existing MMELs, regularly updated',
    'New MELs tailored to your operation and registry, automatically',
    'Easy to use, but powerful, web platform',
    'Fully compliant MELs issued digitally or for printing',
  ]
}

const reviseTab = {
  title: 'Revising MELs is a manual process that requires checking for differences then adapting and reformatting your existing files. Not any more.',
  text: 'As revisions to the MMELs are issued by OEMs, these changes are automatically identified by our platform and made available to you. From there, all you have to do is review the changes and decide whether you wish to accept them into your MEL and a new MEL is automatically drafted for you.',
  text2: 'Changes made to other MELs in the fleet, such as in line with new regulator wording, are treated in exactly the same way. You choose the parts you want to include, and a new MEL can be issued instantly.',
  img: MelRevise,
  ticks: [
    'Issue MEL revisions with just a few clicks',
    'Changes automatically identified as new MMELs are issued',
    'Ensure consistency between MELs by applying changes to a selection of MELs, or prepare changes for the entire fleet ready for the next MEL issuances',
  ]
}

const publishTab = {
  title: 'MELs issued digitally and consistently',
  text: 'Once created or revised, MELs are issued and formatted automatically. The MELs can be browsed in full through our web portal or issued as compliant PDFs for separate distribution.',
  img: MelPublish,
  ticks: [
    'Simplified MEL viewing through the web portal, unconstrained by the need to format for print',
    'Fully integrated with the TrustFlight Tech Log, instantly replacing the MEL for an aircraft when a new revision is approved ensuring all users are always using the latest revision',
    'MEL reviewing system for your regulator to quickly and easily view and approve your changes',
    'Full PDF export support to enable continued MEL distribution using your in-house systems or by printing',
  ]
}

const TickRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  span {
    margin-left: 10px;
    max-width: 480px;
  }
`;

const StyledLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: #1E5BB5;
`;

const FormTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #242d41;
  margin-bottom: 30px;
`;

export default class melManager extends Component  {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      activeTab: 'create',
      formSubmitted: '',
      formMessage: null,
      fullName: '',
      fleetSize: '',
      email: '',
      aircraftTypes: '',
      company: '',
      authority: '',
    };
  }
  // const [activeTab, setActiveTab] = useState('create');
  // const [fullName, setFullName] = useState('');
  // const [fleetSize, setFleetSize] = useState('');
  // const [email, setEmail] = useState('');
  // const [aircraftTypes, setAircraftTypes] = useState('');
  // const [company, setCompany] = useState('');
  // const [authority, setAuthority] = useState('');
  // const [loading, setLoading] = useState(false);
  // const [formSubmitted, setFormSubmitted] = useState('');
  // const [formMessage, setFormMessage] = useState(null);
  // console.log(activeTab);


  handleTabClick = (input) => {
    this.setState({ activeTab: input });
  }

  formSuccess = () => {
    this.setState({ formSubmitted: 'success', formMessage: 'Thank you for contacting us. A member of our team will respond as quickly as possible.' });
  }

  formError = () => {
    this.setState({ formSubmitted: 'error', formMessage: 'Unfortunately we encountered a problem submitting your enquiry. Please try again or email <a href="mailto:info@trustflight.com">info@trustflight.com</a> instead.' });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const formData = {};
    const key = 'form-name';
    Object.keys(this.refs).map((key) => formData[key] = this.state[key])
    formData[key] = 'mel-manager';
    const axiosOptions = {
      url: this.props.location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData)
    };
    const { fullName, fleetSize, email, aircraftTypes, authority } = this.state;

    if (!fullName) {
      message.error('Please fill in name.')
      return;
    }
    if (!fleetSize) {
      message.error('Please fill in fleet size.')
      return;
    }
    if (!email) {
      message.error('Please fill in email.')
      return;
    }
    if (!aircraftTypes) {
      message.error('Please fill in aircraft types.')
      return;
    }
    if (!authority) {
      message.error('Please fill in authority or registry.')
      return;
    }
    this.setState({ loading: true });

    axios(axiosOptions)
      .then(response => {
        this.setState({
          fullName: '',
          fleetSize: '',
          email: '',
          company: '',
          aircraftTypes: '',
          authority: '',
        })
        this.formSuccess();
      })
      .catch(err => {
        this.setState({
          submitting: false
        });
        this.formError();
      });
  } 

  TabContent = () => {
    const { activeTab } = this.state;
    let content = createTab;
    if (activeTab === 'create') content = createTab;
    if (activeTab === 'revise') content = reviseTab;
    if (activeTab === 'publish') content = publishTab;
    const tickContent = content.ticks.map((item) => (
      <TickRow className={styles.tickRow}>{tick} <span>{item}</span></TickRow>
    ))
    return (
      <Row className={styles.tabContentWrapper}>
        <Col lg={6} md={12}>
          <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
            <TabInnerTitle>{content.title}</TabInnerTitle>
            <TabText>{content.text}
            </TabText>
            {content.text2 && (<><br /><TabText>{content.text2}
            </TabText></>)}
            {content.text3 && (<><br /><TabText>{content.text3}
            </TabText></>)}
            <TickWrapper>
              {tickContent}
            </TickWrapper>
          </ScrollAnimation>
        </Col>
        <Col lg={6} md={12}>
          <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
            <img src={content.img} alt="mel" className={styles.tabImage} />
          </ScrollAnimation>
        </Col>
      </Row>
    )
  };

  render() {
    const { activeTab, formSubmitted, formMessage } = this.state;
    return (
      <Layout>
        <SEO title="MEL Manager" description="Issue and revise Minimum Equipment Lists (MELs) with ease for your entire fleet. Automate the creation, revision and publication of compliant MELs." />
        <PageHeader
          header="Hassle-free <br /><span>MEL Management</span>"
          subHeader="Issue and revise Minimum Equipment Lists (MELs) with ease for your entire fleet. Automate the creation, revision and publication of compliant MELs."
          linkText="Sign up for early access"
          bigHeader
          bigImage={melFeatured}
          link="/contact?demo"
          scroll
          whiteLink
          svgImage
        />
        <Container>
          <Row className={styles.firstRow}>
            <Col lg={6} md={12}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <Title24>
                  Save time whilst ensuring compliance and consistency
                </Title24>
                <p className={styles.description}>
                  Issue and revise MELs in a fraction of the time using a simple but powerful MEL building platform. Designed from the ground-up to simplify the task of MEL management, our system saves countless hours of administrative work.
                </p>
                <p className={styles.description}>
                  Build MELs from scratch using an existing MMEL as a template, or manage and revise your current MELs. Our service covers the full MEL lifecycle from creation through each revision without you needing to ever worry about formatting.
                </p>
                <StyledLink onClick={() => document.getElementById('regulators').scrollIntoView({ behavior: "smooth" })}><span>Discover the MEL Manager for Regulators </span><img src={goArrow} alt="goArrow" className={styles.goArrow} /></StyledLink>
              </ScrollAnimation>
            </Col>
            <Col lg={6} md={12}>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                <img
                  src={MelOverview}
                  alt="mel"
                  className={styles.melImage}
                />
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
          <GradientSection>
            <div className={styles.tabWrapper}>
              <div className={styles.borderLine} />
              <Row className={styles.tabRow}>
                <Col xs={4} className={styles.tabCol}>
                  <TabTitle>Create</TabTitle>
                  <TabButton onClick={() => this.handleTabClick('create')}>
                    <TabLine active={activeTab === 'create'} />
                    <TabNumber active={activeTab === 'create'}>1</TabNumber>
                  </TabButton>
                </Col>
                <Col xs={4} className={styles.tabCol}>
                  <TabTitle>Revise</TabTitle>
                  <TabButton onClick={() => this.handleTabClick('revise')}>
                    <TabLine active={activeTab === 'revise'} />
                    <TabNumber active={activeTab === 'revise'}>2</TabNumber>
                  </TabButton>
                </Col>
                <Col xs={4} className={styles.tabCol}>
                  <TabTitle>Publish</TabTitle>
                  <TabButton onClick={() => this.handleTabClick('publish')}>
                    <TabLine active={activeTab === 'publish'} />
                    <TabNumber active={activeTab === 'publish'}>3</TabNumber>
                  </TabButton>
                </Col>
              </Row>
            </div>
            {this.TabContent()}
          </GradientSection>
        <Container>
          <Row className={styles.middleRow}>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} className={styles.middleText}>
              <Title30 className={styles.title30}>Fully integrated with the TrustFlight suite of products</Title30>
              <SubTitle20 className={styles.subTitle20}>The MEL Manager system can be used independently, or integrated with our Tech Log and Registry products.</SubTitle20>
            </ScrollAnimation>
            <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} className={styles.middleImage}>
              <img src={MelTechLog} alt="mel" className={styles.melMiddleImage} />
            </ScrollAnimation>
          </Row>
          <Row className={styles.firstRow}>
            <Col lg={4}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
              <Title24>Track, review and publish your changes</Title24>
              <p className={styles.description}>
                Changes you make or propose to an MEL can be easily reviewed by other members of your team, or even the regulator. Once a change has been made, it can be instantly applied to other aircraft of the same type, operating under the same regulator, or even applied to your entire fleet.
              </p>
              </ScrollAnimation>
            </Col>
            <Col lg={{ span: 7, offset: 1 }}>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                <img src={editorGif} alt="mel overview" />
              </ScrollAnimation>
            </Col>
          </Row>
          <Row className={styles.firstRow}>
            <Col lg={7} className={styles.mobColHidden}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <img src={fleetGif} alt="mel overview" className={styles.screenImg} />
              </ScrollAnimation>
            </Col>
            <Col lg={{ span: 4, offset: 1 }} className={styles.mobColPadding}>
              <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
              <Title24>Instant oversight of your entire fleet</Title24>
              <p className={styles.description}>
                From a single location you are able to have oversight of your entire fleet. View the current MELs, see the status of draft MELs and ensure that revisions are issued well before their deadlines with just a few clicks.
              </p>
              </ScrollAnimation>
            </Col>
            <Col lg={7} className={styles.mobColVisible}>
              <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                <img src={fleetGif} alt="mel overview" className={styles.screenImg} />
              </ScrollAnimation>
            </Col>
          </Row>
          </Container>
            <GradientSectionReversed id="regulators">
              <Container>
                <Row className={styles.regulatorRow}>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} className={styles.middleTextPad}>
                    <Title30>Powerful and simple to review, with tools built just for regulators.</Title30>
                    <SubTitle20>MELs built using the platform contain a full history back through every MMEL revision. With tools built just for regulators, our platform makes it easy to keep on top of MEL reviews.</SubTitle20>
                  </ScrollAnimation>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} className={styles.middleImage}>
                    <img src={MelRegulator} alt="mel regulator" className={styles.regulatorImage} />
                  </ScrollAnimation>
                  <Row>
                    <Col lg={7} className={styles.mobColHidden}>
                      <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                        <img src={regulatorsGif} alt="mel overview" />
                      </ScrollAnimation>
                    </Col>
                    <Col lg={5} className={styles.mobColPadding}>
                      <ScrollAnimation animateOnce animateIn="fadeInRight" offset={0}>
                      <p className={styles.description}>
                        Reviewing an MEL for approval becomes a simple task. No need to spot-check items or compare documents, the platform shows you clearly what text has been altered or updated since the last issue, and how it differs from the latest MMEL. Instead of reviewing the whole document, you only need to check the differences.
                      </p>
                      <div className={styles.tickRow}>{tick} <span>Instantly see the status of all MELs on your registry, or within your approval scope</span></div>
                      <div className={styles.tickRow}>{tick} <span>Review MELs in just a few clicks with clearly indicated changes, ensuring you always know what's new</span></div>
                      <div className={styles.tickRow}>{tick} <span>Full history view that traces each MEL item back to the MMEL</span></div>
                      <div className={styles.tickRow}>{tick} <span>Ensure consistency and compliance from the start with our field validation and automated document creation</span></div>
                      <div className={styles.tickRow}>{tick} <span>Built-in approval process, no signatures or paperwork to fill out</span></div>
                      </ScrollAnimation>
                    </Col>
                    <Col lg={7} className={styles.mobColVisible}>
                      <ScrollAnimation animateOnce animateIn="fadeInLeft" offset={0}>
                        <img src={regulatorsGif} alt="mel overview" />
                      </ScrollAnimation>
                    </Col>
                  </Row>
                </Row>
              </Container>
            </GradientSectionReversed>
          <Container>
          {/* <Started
            title="Join the new standard of MELs"
            text="Our MEL management system is currently being limited to a select group of operators. Sign up now to get early access or contact one of out sales team to learn more."
            buttonText="Ready to go paperless?"
          /> */}
        </Container>
        <div id="signUpForm" className={styles.formWrapper}>
          <div className={styles.formContainer}>
            <FormTitle>Sign up for early access</FormTitle>
            {formSubmitted !== '' ? (
              <>
                <span>{formMessage}</span>
              </>
            ) : (
            <form name="mel-manager" method="post" data-netlify="true" data-nelify-honeypot="bot-field" onSubmit={(event) => this.handleSubmit(event)}>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>Full name</Form.Label>
                    <Input
                      type="text"
                      placeholder="Your name"
                      className={styles.formElement}
                      name="fullName"
                      ref="fullName"
                      onChange={e => this.setState({ fullName: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>E-mail address</Form.Label>
                    <Input
                      type="email"
                      placeholder="Your e-mail address"
                      className={styles.formElement}
                      name="email"
                      ref="email"
                      onChange={e => this.setState({ email: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>Company (Optional)</Form.Label>
                    <Input
                      type="text"
                      placeholder="Your company"
                      className={styles.formElement}
                      name="company"
                      ref="company"
                      onChange={e => this.setState({ company: e.target.value })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>Fleet size</Form.Label>
                    <Input
                      type="text"
                      placeholder="Your fleet size"
                      className={styles.formElement}
                      name="fleetSize"
                      ref="fleetSize"
                      onChange={e => this.setState({ fleetSize: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>Aircraft types</Form.Label>
                    <TextArea
                      type="text"
                      placeholder="Your aircraft types"
                      className={styles.formElement}
                      name="aircraftTypes"
                      ref="aircraftTypes"
                      onChange={e => this.setState({ aircraftTypes: e.target.value })}
                      autoSize={{ minRows: 3 }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className={styles.formLabel}>Authority or Registry</Form.Label>
                    <Input
                      type="text"
                      placeholder="Authority or registry"
                      className={styles.formElement}
                      name="authority"
                      ref='authority'
                      onChange={e => this.setState({ authority: e.target.value })}
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    htmlType="submit"
                    className={styles.formSubmit}

                  >
                    Sign up
                  </Button>
                </Col>
              </Row>
            </form>
            )}
          </div>
        </div>
        <AlsoInterestedIn page="mel" />
      </Layout>
    );
  }
};